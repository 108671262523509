/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module TopicsListing
 */
import React from 'react';
import {
  Configure,
  Index,
  InstantSearch,
  useInstantSearch,
  useSortBy,
} from 'react-instantsearch';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '@io/web-tools-io/dist/hooks/useAuth';
import { ArticlesHitsSlider } from '../ArticlesListing/ArticlesListing';
import { JourneysHitsSlider } from '../JourneysListing/JourneysListing';
import useAlgoliaClient from '../../hooks/useAlgoliaClient';
import FindsSearchBox from '../FindsSearchBox/FindsSearchBox';
import SearchResultsText from '../SearchResultsText/SearchResultsText';
import SearchTagFilters from '../SearchTagFilters/SearchTagFilters';
import FilterSelect from '../FilterSelect/FilterSelect';
import {
  ACTIONS,
  ARTICLES_INDEX,
  ARTICLES_SORT_ITEMS,
  EVENTS,
  JOURNEYS_INDEX,
  JOURNEYS_SORT_ITEMS,
} from '../../helpers/constants';
import './TopicsListing.scss';

/**
 * Wrapper component for SearchResultsText that accesses Algolia InstantSearch hits.
 *
 * @param {object} props - The component props object.
 * @param {Function} props.handleReset - Function to reset the search.
 * @param {string} props.searchInput - The current search input text.
 *
 * @returns {React.ReactElement} - The SearchResultsText populated with aggregated hit count and search text.
 */
function ResultsText({ handleReset, searchInput }) {
  const { scopedResults } = useInstantSearch();
  const totalResults = scopedResults.reduce((acc, curr) => {
    return acc + (curr?.results?.nbHits || 0);
  }, 0);
  return (
    <SearchResultsText
      onClearResults={handleReset}
      resultCount={totalResults}
      searchText={searchInput}
    />
  );
}

/**
 * Transforms the article items returned by the Algolia search.
 *
 * @param {Array<ArticleItem>} items - The original array of article items returned from the Algolia search.
 *
 * @returns {Array<ArticleItem>} The transformed array of article items, limited to a maximum of 50 results.
 */
const transformArticles = (items) => {
  if (!items?.length) {
    return items;
  }

  const transformed = items.map((item) => {
    const newItem = item;
    const id = item.data?.path;
    const n = id.lastIndexOf('/');
    newItem.data['@name'] = id.substring(n + 1);
    return newItem;
  });

  return transformed;
};

/**
 * A virtual widget is an InstantSearch widget mounted in the app but which doesn’t render anything.
 *
 * This virtual component interacts with a piece of the UI state that maps to one or more Algolia search parameters.
 *
 * @returns {null} Null (Inner function logic but no return).
 */
export function VirtualSort() {
  useSortBy({
    items: ARTICLES_SORT_ITEMS,
  });

  return null;
}

/**
 * Represents a wrapper containing Journeys and Articles search, filters, and listing elements.
 *
 * @param {object} props - The component props object.
 * @param {object} [props.metadata] - Data object of journey/article metadata.
 * @param {string} [props.predefinedTopic] - The predefined topic value.
 * @param {object} props.theme - The theme value.
 * @param {object} props.title - The title for the component.
 *
 * @returns {React.ReactElement} - The TopicsListing component.
 */
const TopicsListing = ({ metadata, predefinedTopic, theme, title }) => {
  const { user } = useAuth();
  const urlParams = new URLSearchParams(window.location.search);
  const searchClient = useAlgoliaClient();
  const [searchInput, setSearchInput] = React.useState('');
  const childRef = React.useRef(null);
  const [showResults, setShowResults] = React.useState(false);
  const tagFromParams = urlParams.get('includeFilter');
  const tags = metadata?.['mgnl:tags'] || [];
  const tagFilters = tagFromParams ? [tagFromParams, ...tags] : tags;
  const getActiveTag = (tag) => (tagFilters?.includes(tag) ? tag : '');
  const [filter, setFilter] = React.useState(
    predefinedTopic || getActiveTag(urlParams.get('activeTag')?.toLowerCase()),
  );

  const setActiveTag = (tag) => {
    if (getActiveTag(tag)) {
      setFilter(tag !== filter ? tag : '');
    }
  };

  /**
   * Convenience function to trigger callSegmentTrack.
   *
   * @param {object} params - The function params object.
   * @param {string} [params.action] - Optional value for action.
   * @param {string} [params.event] - Optional event associated with the tracking request.
   * @param {string} [params.label] - Optional value for label.
   * @param {string} [params.value] - Optional value associated with the tracking request.
   */
  function callAnalytics({ action, event, label, value }) {
    callSegmentTrack({
      event: event || EVENTS.buttonAction,
      properties: {
        action: action || ACTIONS.clicked,
        component: 'Topics Listing',
        component_url: null, // Inner-component scroll, no component URL.
        label,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        value,
      },
    });
  }

  /**
   * Handler function for Next button click.
   *
   * @param {string} filterItem - The filter value.
   */
  function handleTagClick(filterItem) {
    callAnalytics({ label: filterItem });
    setActiveTag(filterItem);
  }

  /**
   * Handler function for reset event.
   */
  function handleReset() {
    callAnalytics({ label: 'Reset' });
    if (childRef.current) {
      childRef.current.handleReset();
    }
  }

  /**
   * Handler function for the state change of the UI, syncing the search query between indices.
   */
  const onStateChange = React.useCallback(({ uiState, setUiState }) => {
    const mainQuery = uiState[JOURNEYS_INDEX]?.query || '';
    const journeysSortBy = uiState[JOURNEYS_INDEX]?.sortBy || '';
    const articlesSortBy = journeysSortBy.replace('journey', 'articles');

    const newState = {
      [ARTICLES_INDEX]: {
        ...uiState[ARTICLES_INDEX],
        query: mainQuery,
        sortBy: articlesSortBy,
      },
      [JOURNEYS_INDEX]: {
        ...uiState[JOURNEYS_INDEX],
        query: mainQuery,
        sortBy: journeysSortBy,
      },
    };

    setUiState(newState);
  }, []);

  /**
   * Convenience effect to control the display of search results.
   * This effect introduces a small delay before updating the state that controls the display of results text, and helps to:
   * 1. Avoid rapid UI that could cause flickering.
   * 2. Reduce the number of re-renders when the user is typing quickly.
   * 3. Fix the issue: "Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.".
   */
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowResults(searchInput.length > 0);
    }, 100);

    return () => clearTimeout(timer);
  }, [searchInput]);

  return (
    <div className={`topics-listing-wrapper ${theme}`}>
      {title ? <h1 className="topic-title">{title}</h1> : null}
      <InstantSearch
        future={{
          preserveSharedStateOnUnmount: true,
        }}
        indexName={JOURNEYS_INDEX}
        onStateChange={onStateChange}
        searchClient={searchClient}
      >
        <Configure filters={filter ? `data.topics:${filter}` : ''} />
        <div className="filters">
          <div className="left-filters">
            <div className="search-input-wrapper">
              <FindsSearchBox
                ref={childRef}
                setSearchInput={setSearchInput}
                theme={theme}
              />
            </div>
            <FilterSelect items={JOURNEYS_SORT_ITEMS} theme={theme} />
          </div>
          <SearchTagFilters
            activeFilter={filter}
            handleTagClick={handleTagClick}
            tagFilters={tagFilters}
          />
        </div>

        {showResults ? (
          <ResultsText handleReset={handleReset} searchInput={searchInput} />
        ) : null}

        <JourneysHitsSlider
          appliedTheme={theme}
          parentComponentName="Topics Listing"
          title="Journeys"
        />

        <Index indexName={ARTICLES_INDEX}>
          <Configure filters={filter ? `data.topics:${filter}` : ''} />
          <VirtualSort />
          <ArticlesHitsSlider
            appliedTheme={theme}
            parentComponentName="Topics Listing"
            title="Articles"
            transformArticles={transformArticles}
          />
        </Index>
      </InstantSearch>
    </div>
  );
};

export default TopicsListing;
