/**
 * @module FilterSelect
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { useSortBy } from 'react-instantsearch';
import ChevronUp from './ChevronUp';
import { COLOR_THEMES } from '../../helpers/constants';
import './FilterSelect.scss';

/**
 * Represents a component that displays a dropdown filter menu.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.className] - The additional CSS class name for custom styling.
 * @param {Array<SortByItem>} props.items - Array of sorting options.
 * @param {string} props.theme - The current theme of the application.
 *
 * @returns {React.ReactElement} - The FilterSelect component.
 */
function FilterSelect({ className, items, theme }) {
  const { currentRefinement, options, refine } = useSortBy({ items });

  const themeClassName = theme === COLOR_THEMES['dark-mode'] ? 'dark' : 'light';
  const customClassName = className || '';

  return (
    <div
      className={`filter-select-wrapper ${themeClassName} ${customClassName}`.trim()}
    >
      <select
        className="filter-select"
        onChange={(event) => refine(event.target.value)}
        value={currentRefinement}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <ChevronUp />
    </div>
  );
}

export default FilterSelect;
